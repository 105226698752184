import React, {
  useEffect,
  useState,
  // Fragment
} from "react";
import {
  usePreviewJoin,
  // selectLocalPeer,
  // useHMSStore,
  // selectIsLocalVideoEnabled,
  useAVToggle,
} from "@100mslive/react-sdk";
import {
  styled,
  flexCenter,
  // Text,
  // StyledVideoTile,
  // Loading,
  // Video,
  // useBorderAudioLevel,
  // useTheme,
  // Avatar,
  Flex,
  // textEllipsis,
} from "@100mslive/react-ui";
// import { SettingsIcon } from "@100mslive/react-icons";
// import { AudioVideoToggle } from "../AudioVideoToggle";
// import SettingsModal from "../Settings/SettingsModal";
// import TileConnection from "../Connection/TileConnection";
import PreviewName from "./PreviewName";
// import IconButton from "../../IconButton";
// import { VirtualBackground } from "../../plugins/VirtualBackground/VirtualBackground";
import {
  useUserPreferences,
  UserPreferencesKeys,
  defaultPreviewPreference,
} from "../hooks/useUserPreferences";
import {
  signIn,
  getUserProfileData,
  generateToken,
} from "../../services/tokenService";
import { ToastManager } from "../Toast/ToastManager";

const PreviewJoin = ({
  token,
  onJoin,
  env,
  skipPreview,
  initialName,
  initialEmail,
  ws,
  meeting_id,
  wsId,
  dc_code,
  dc_region,
  setAuthToken,
}) => {
  const [previewPreference, setPreviewPreference] = useUserPreferences(
    UserPreferencesKeys.PREVIEW,
    defaultPreviewPreference
  );
  const [name, setName] = useState(initialName || previewPreference.name);
  const [email, setEmail] = useState(initialEmail || previewPreference.email);
  const [password, setPassword] = useState("");
  const { isLocalAudioEnabled, isLocalVideoEnabled } = useAVToggle();
  const [previewError, setPreviewError] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const { preview, join } = usePreviewJoin({
    name,
    email,
    token,
    initEndpoint: env ? `https://${env}-init.100ms.live/init` : undefined,
    initialSettings: {
      isAudioMuted: skipPreview || previewPreference.isAudioMuted,
      isVideoMuted: skipPreview || previewPreference.isVideoMuted,
    },
    captureNetworkQualityInPreview: true,
    handleError: (_, method) => {
      if (method === "preview") {
        setPreviewError(true);
      }
    },
  });
  const savePreferenceAndJoin = () => {
    if (token) {
      setPreviewPreference({
        name,
        email,
        isAudioMuted: !isLocalAudioEnabled,
        isVideoMuted: !isLocalVideoEnabled,
      });
      join();
      onJoin && onJoin();
    }
  };
  useEffect(() => {
    savePreferenceAndJoin();
  }, [token]);

  const DecodeUserJoined = () => {
    setInProgress(true);
    const payload = {
      username: email.toLocaleLowerCase(),
      password: password,
      workspace: ws,
    };
    signIn(ws, payload)
      .then(res => {
        const profilePayload =
          "email=" + email.toLocaleLowerCase() + "&workspace_name=" + ws;
        getUserProfileData(profilePayload).then(profileResponse => {
          const workspaceId = profileResponse.data.workspace_id;
          setName(profileResponse.data.name);
          const tokenPayload = {
            dc_code: profileResponse.data.datacenter_code,
            dc_region: profileResponse.data.datacenter_region,
            email: profileResponse.data.email,
            meeting_id: meeting_id,
            name: profileResponse.data.name,
            user_id: profileResponse.data.id,
            workspace_id: workspaceId,
          };
          generateToken(tokenPayload).then(data => {
            setAuthToken(data.access_token);
            setTimeout(() => {
              savePreferenceAndJoin();
              setInProgress(false);
            }, 200);
          });
        });
      })
      .catch(error => {
        console.error(error.message);
        ToastManager.addToast({
          title: error.message,
          variant: "error",
        });
        setInProgress(false);
      });
  };

  const guestUserJoined = () => {
    setInProgress(true);
    const tokenPayload = {
      dc_code: dc_code,
      dc_region: dc_region,
      email: email,
      meeting_id: meeting_id,
      name: name,
      user_id: null,
      workspace_id: wsId,
    };

    generateToken(tokenPayload).then(data => {
      setAuthToken(data.access_token);
      savePreferenceAndJoin();
      setInProgress(false);
    });
  };

  return (
    <Container>
      <Flex
        align="center"
        justify="center"
        css={{
          width: "100%",
          "@sm": { width: "100%" },
        }}
      >
        <Flex
          align="center"
          justify="center"
          css={{
            "@sm": { width: "100%" },
            flexDirection: "column",
            width: "50%",
          }}
        >
          <PreviewName
            name={name}
            email={email}
            password={password}
            onPasswordChange={setPassword}
            onNameChange={setName}
            onEmailChange={setEmail}
            enableJoin={true}
            onJoin={savePreferenceAndJoin}
            DecodeUserJoined={DecodeUserJoined}
            ws={ws}
            guestUserJoined={guestUserJoined}
            inProgress={inProgress}
          />
        </Flex>
        {/* <Flex
          align="center"
          justify="center"
          css={{
            "@sm": { width: "100%" },
            flexDirection: "column",
            width: "50%",
          }}
        >
          <PreviewTile name={name} email={email} error={previewError} />
          <PreviewControls
            enableJoin={true}
            savePreferenceAndJoin={savePreferenceAndJoin}
          />
        </Flex> */}
      </Flex>
    </Container>
  );
};

const Container = styled("div", {
  width: "100%",
  ...flexCenter,
  flexDirection: "column",
  px: "$10",
});

// const PreviewTile = ({ name, email, error }) => {
//   const localPeer = useHMSStore(selectLocalPeer);
//   const borderAudioRef = useBorderAudioLevel(localPeer?.audioTrack);
//   const isVideoOn = useHMSStore(selectIsLocalVideoEnabled);
//   const {
//     aspectRatio: { width, height },
//   } = useTheme();
//   return (
//     <StyledVideoTile.Container
//       css={{
//         bg: "$surfaceDefault",
//         aspectRatio: width / height,
//         // width: "unset",
//         // height: "min(360px, 60vh)",
//         mt: "$12",
//         "@sm": {
//           height: "unset",
//           width: "min(360px, 100%)",
//           maxWidth: "100%",
//         },
//         width: "100%",
//         height: "80%",
//       }}
//       ref={borderAudioRef}
//     >
//       {localPeer ? (
//         <>
//           <TileConnection name={name} peerId={localPeer.id} hideLabel={true} />
//           <Video
//             mirror={true}
//             trackId={localPeer.videoTrack}
//             data-testid="preview_tile"
//           />
//           {!isVideoOn ? (
//             <StyledVideoTile.AvatarContainer>
//               <Avatar name={name} data-testid="preview_avatar_tile" />
//               <Text css={{ ...textEllipsis("75%") }} variant="body2">
//                 {name}
//               </Text>
//             </StyledVideoTile.AvatarContainer>
//           ) : null}
//         </>
//       ) : !error ? (
//         <Loading size={100} />
//       ) : null}
//     </StyledVideoTile.Container>
//   );
// };

// const PreviewControls = () => {
//   return (
//     <Flex
//       justify="between"
//       css={{
//         width: "100%",
//         mt: "$8",
//       }}
//     >
//       <Flex css={{ gap: "$4" }}>
//         <AudioVideoToggle compact />
//         {/* <VirtualBackground /> */}
//       </Flex>
//       <PreviewSettings />
//     </Flex>
//   );
// };

// const PreviewSettings = React.memo(() => {
//   const [open, setOpen] = useState(false);
//   return (
//     <Fragment>
//       <IconButton
//         data-testid="preview_setting_btn"
//         onClick={() => setOpen(value => !value)}
//       >
//         <SettingsIcon />
//       </IconButton>
//       {open && <SettingsModal open={open} onOpenChange={setOpen} />}
//     </Fragment>
//   );
// });

export default PreviewJoin;
